import React, { Suspense } from 'react';
import Loadable from 'react-loadable';
import { BrowserRouter as Router } from 'react-router-dom';
// Themes
import './assets/scss/DefaultTheme.scss';
import { isUserAuthenticated } from './helpers/authUtils';
import { routes } from './routes';

const loading = () => <div></div>;

const NonAuthLayout = Loadable({
	loader: () => import('./components/NonAuthLayout'),
	render(loaded, props) {
		const Component = loaded.default;
		return <Component {...props} />;
	},
	loading,
});

const AuthLayout = Loadable({
	loader: () => import('./components/AuthLayout'),
	render(loaded, props) {
		const Component = loaded.default;
		return <Component {...props} />;
	},
	loading,
});

const withLayout = (WrappedComponent: any) => {
	const HOC = class extends React.Component {
		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	return HOC;
};

const App: React.FC = () => {
	const getLayout = () => {
		return isUserAuthenticated() ? AuthLayout : NonAuthLayout;
	};

	return (
		<Router>
			<>
				{routes.map((route, index) => {
					return (
						<route.route
							key={index}
							exact={route.exact}
							path={route.path}
							component={withLayout((props: any) => {
								const Layout = getLayout();
								return (
									<Suspense fallback={loading()}>
										<Layout {...props} title={route.name}>
											<route.component {...props} />
										</Layout>
									</Suspense>
								);
							})}
						/>
					);
				})}
			</>
		</Router>
	);
};

export default App;
