import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';

/**
 * Checks if user is authenticated
 */
export const isUserAuthenticated = (): boolean => {
	const user = getLoggedInUser();

	if (!user) {
		return false;
	}

	const decoded = jwtDecode(user.token) as any;

	const currentTime = Date.now() / 1000;
	if (decoded.exp < currentTime) {
		console.warn('access token expired');
		return false;
	} else {
		return true;
	}
};

/**
 * Save user authentication token
 */
export const setUserToken = async (token: string): Promise<boolean> => {
	const cookies = new Cookies();
	cookies.set('user', { token });
	return true;
};

/**
 * Delete user authentication token
 */
export const removeUserToken = (): void => {
	const cookies = new Cookies();
	cookies.remove('user');
};

/**
 * Returns the logged in user
 */
export const getLoggedInUser = (): { token: string } | null => {
	const cookies = new Cookies();
	const user = cookies.get('user');
	return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};
