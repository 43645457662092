import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isUserAuthenticated } from './helpers/authUtils';

const Login = React.lazy(() => import('./pages/Login'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));

export const PrivateRoute = ({ component: Component, roles, ...rest }: any) => (
	<Route
		{...rest}
		render={(props) => {
			const isAuthTokenValid = isUserAuthenticated();

			if (!isAuthTokenValid) {
				return <Redirect to='/login' />;
			}

			return <Component {...props} />;
		}}
	/>
);

interface RouteConfig {
	path: string;
	name?: string;
	component: any;
	exact?: boolean;
	route: any;
}

export const routes: RouteConfig[] = [
	{ path: '/login', name: 'Login', component: Login, route: Route },
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard,
		route: PrivateRoute,
	},
	{
		path: '/login',
		exact: true,
		component: () => <Redirect to='/dashboard' />,
		route: PrivateRoute,
	},
	{
		path: '/',
		exact: true,
		component: () => <Redirect to='/dashboard' />,
		route: PrivateRoute,
	},
];
